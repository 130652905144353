function Footer(){
    return (
        <div className="container-fluid">
            <a href="tel:+917068696869" className="a-float-call" target="_blank" rel="noreferrer">
                <i className="fa fa-phone my-float"></i>
            </a>
            <a href="https://api.whatsapp.com/send?phone=917068696869&text=Hello" className="a-float" target="_blank" rel="noreferrer">
                <i className="fa fa-whatsapp my-float"></i>
            </a>
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                <h6 className="col-md-4 mb-0">© 2023 Dialxprt</h6>

                <a href="/" className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
                <svg className="bi me-2" width="40" height="32"></svg>
                </a>

                <ul className="nav col-md-4 justify-content-end">
                <li className="nav-item"><a href="/" className="nav-link px-2 text-muted">Home</a></li>
                </ul>
            </footer>
        </div>
    )
}
export default Footer;