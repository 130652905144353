import { configureStore } from '@reduxjs/toolkit';
import { applyMiddleware } from 'redux';
// import CounterReducer from './reducers/counterReducer';
import thunk from 'redux-thunk';
import allReducers from './reducers/index';
// import postReducer from '../state/reducers/apiReducer'
// import counterReducer from './reducers/counterReducer';
const initialState = { 
    baseReducer: 
    { 
        toggleEdit: false, 
        isDisabled: true
    }
}
// const store = createStore(allReducers, initialState, applyMiddleware(thunk));
const store = configureStore({
    reducer:allReducers,
    initialState:initialState,
    applyMiddleware:applyMiddleware(thunk)
})
export default store;   