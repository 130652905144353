import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCategories, getFilterCategories } from '../../state/reducers/apiReducer';
import { Link } from 'react-router-dom';
import { staticBaseUrl } from "../variable/static_variables";
function Categories() {
    const { id } = useParams()
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCategories(id))
    }, [dispatch,id]);
    const categories_data = useSelector(state => state.postReducer.categories);
    const allcategories = useSelector(state => state.postReducer.allcategories);

    const filterCategories = (val) => {
        if (val !== "" || val !== undefined) {
            const dd = allcategories.filter((ele) => {
                let dd = String(ele.id);
                let name = String(ele.name)
                return (dd.includes(val) || name.toLowerCase().includes(val.toLowerCase()))
            })
            console.log(dd)
            dispatch(getFilterCategories(dd))
        }

    }
    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-12 mt-5">
                    <form className="d-flex">
                        <input onChange={(e) => filterCategories(e.target.value)} className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                    </form>
                </div>
                {categories_data?.map((d) => {
                    let linkUrl;
                    if(d.children.length >0){
                        linkUrl = "/Categories/"+d.id;
                    }else{
                        linkUrl = "/Service-Provider/"+d.id;
                    }
                    let icon_categories = (d.icon)?(staticBaseUrl+d.icon):(staticBaseUrl+"image/noimage.png")
                    return (
                        <div className="col-md-4" key={d.id}>
                            <Link to={linkUrl}>
                                <div className="category-card">
                                    <div className="title row" style={{alignItems:"center"}}>
                                        <div className="col-8">
                                            <h5>{d.name}</h5>
                                            <p className="">{d.name}</p>
                                        </div>
                                        <div className="col-4">
                                            <img src={icon_categories} style={{ width: "100%" }} alt="i" className='image-border' />
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )
                })}
            </div>
        </div>




    );
}

export default Categories;
