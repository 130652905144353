import './components/css/style.css';
import Header from './components/header';
import RoutePage from './components/Categories/routepage';
import Footer from './components/footer';

function App() {
  return (
    <div>
      <Header />
      <RoutePage/>
      <Footer />

    </div>
  );
}

export default App;
