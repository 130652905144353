// import { useDispatch, useSelector } from "react-redux";
// import { useEffect } from "react";
// import { getAddresses } from "../state/reducers/apiReducer";
import { staticBaseUrl } from "./variable/static_variables";

function Header() {
  // const dispatch = useDispatch();
  //   useEffect(() => {
  //       dispatch(getAddresses())
  //   }, [dispatch]);
    // const address = useSelector(state => state.postReducer.addresses);
    return (
      <nav className="navbar navbar-expand-lg text-white fixed-top">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={staticBaseUrl+"image/logo.png"} alt="Logo" height="50" className="d-inline-block align-text-top"/>
          </a>
          {/* <img src={staticBaseUrl+"image/logo.png"} height={"50px"}/>
          <a href='/' className="navbar-brand"><h3>Dialxprt</h3></a> */}
          <div className="d-flex">
            {/* <select className="form-control me-2">
              <option value="">All Address</option>
                {
                  (Array.isArray(address) && address.length>0)?address?.map((d) =>{
                      return (<option value={d.id} key={d.id}>{d.name}</option>)
                    }):""
                  
                }
            </select> */}
          </div>
          
        </div>
      </nav>
    );
  }
  
export default Header;
 