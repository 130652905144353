import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiBaseUrl } from '../../components/variable/static_variables';
export const getCategories = createAsyncThunk("posts/getCategories", async (arg = 0, { getState }) => {
    return fetch(apiBaseUrl + "categories/"+arg, {
        method: 'GET',
        headers: {}
    })
        .then((response) => response.json()).then((response) => response.message);
})
export const getFilterCategories = createAsyncThunk("posts/getFilterCategories", async (arg, { getState }) => {
    return arg;
})
export const getEmployees = createAsyncThunk("posts/getEmployees", async (arg, { getState }) => {
    return fetch(apiBaseUrl + "owners/"+arg, {
        method: 'GET',
        headers: {}
    })
        .then((response) => response.json()).then((response) => response.message);
})
export const getEmployeeDetails = createAsyncThunk("posts/getEmployeeDetails", async (arg, { getState }) => {
    const state = getState();
    return fetch(apiBaseUrl + "ownerdetails/"+state.baseReducer.detailsID, {
        method: 'GET',
        headers: {}
    })
        .then((response) => response.json()).then((response) => response.message);
})
export const getFilterEmployees = createAsyncThunk("posts/getFilterEmployees", async (arg, { getState }) => {
    return arg;
})

export const getAddresses = createAsyncThunk("posts/getAddresses", async (arg, { getState }) => {
    return fetch(apiBaseUrl + "address", {
        method: 'GET',
        headers: {}
    })
        .then((response) => response.json()).then((response) => response.message);
})
export const createRegistration = createAsyncThunk("posts/createRegistration", async (arg, { getState }) => {
    return fetch(apiBaseUrl + "create-expert", {
        method: 'POST',
        headers: {},
        body:arg
    })
        .then((response) => response.json()).then((response) => response.message);
})
const postSlice = createSlice(({
    name: "posts",
    initialState: {
        categories: [],
        allcategories: [],
        employees: [],
        allemployees: [],
        employeedetails: {},
        addresses:[],
        loading: false,
        create_status : false,
    },
    extraReducers: {
        [getCategories.pending]: (state, action) => {
            state.loading = true;
        },
        [getCategories.fulfilled]: (state, action) => {
            state.allcategories = action.payload;
            state.categories = action.payload;
            state.loading = false;
        },
        [getCategories.rejected]: (state, action) => {
            state.loading = false
        },
        [getFilterCategories.pending]: (state, action) => {
            state.loading = true;
        },
        [getFilterCategories.fulfilled]: (state, action) => {
            state.categories = action.payload;
            state.loading = false;
        },
        [getFilterCategories.rejected]: (state, action) => {
            state.loading = false
        },
        [getEmployees.pending]: (state, action) => {
            state.loading = true;
        },
        [getEmployees.fulfilled]: (state, action) => {
            state.allemployees = action.payload;
            state.employees = action.payload;
            state.loading = false;
        },
        [getEmployees.rejected]: (state, action) => {
            state.loading = false
        },
        [getFilterEmployees.pending]: (state, action) => {
            state.loading = true;
        },
        [getFilterEmployees.fulfilled]: (state, action) => {
            state.employees = action.payload;
            state.loading = false;
        },
        [getFilterEmployees.rejected]: (state, action) => {
            state.loading = false
        },
        [getEmployeeDetails.pending]: (state, action) => {
            state.loading = true;
        },
        [getEmployeeDetails.fulfilled]: (state, action) => {
            state.employeedetails = action.payload;
            state.loading = false;
        },
        [getEmployeeDetails.rejected]: (state, action) => {
            state.loading = false
        },
        [getAddresses.pending]: (state, action) => {
            state.loading = true;
        },
        [getAddresses.fulfilled]: (state, action) => {
            state.addresses = action.payload;
            state.loading = false;
        },
        [getAddresses.rejected]: (state, action) => {
            state.loading = false
        },
        [createRegistration.fulfilled]: (state, action) => {
            state.create_status = true;
        }
    }
}
))


export default postSlice.reducer;