import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { headersSaveEdit } from '../../state/actions/baseActions'
import { getEmployees, getFilterEmployees } from '../../state/reducers/apiReducer';
import Details from './details';
import { mediaBaseUrl, staticBaseUrl } from '../variable/static_variables';
// import { staticBaseUrl } from '../variable/static_variables';
function Employee() {
    const { id } = useParams()
    const toggleEditValue = useSelector(state => state.baseReducer.toggleEdit);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getEmployees(id))
    }, [dispatch,id]);
    const employee_data = useSelector(state => state.postReducer.employees);
    const allemployees = useSelector(state => state.postReducer.allemployees);

    const filterEmployees = (val) => {
        if (val !== "" || val !== undefined) {
            const dd = allemployees.filter((ele) => {
                let name = String(ele.name);
                let category = String(ele.category)
                let address = String(ele.address_ids)
                let status = String(ele.status)

                return (category.toLowerCase().includes(val.toLowerCase()) || name.toLowerCase().includes(val.toLowerCase())
                || address.toLowerCase().includes(val.toLowerCase()) || status.toLowerCase().includes(val.toLowerCase())
                )
            })
            dispatch(getFilterEmployees(dd))
        }

    }
    const data = (Array.isArray(employee_data))?employee_data.map((d) => {
        let ribbon = ""        
        if(employee_data.membership_id === 1){
            ribbon = <div className="ribbon ribbon-top-right ribbon-gold"><span>gold</span></div>;
        }else if(employee_data.membership_id === 2){
            ribbon = <div className="ribbon ribbon-top-right ribbon-silver"><span>Silver</span></div>;
        }else if(employee_data.membership_id === 3){
            ribbon = <div className="ribbon ribbon-top-right ribbon-diamond"><span>Diamond</span></div>;
        }
        let image_src = (d.businessPhoto)?mediaBaseUrl+d.businessPhoto:staticBaseUrl+"image/noimage.png"
        return (
                    <li className="list-group-item m-2 employee-list"  key={d.id}>
                        <div className='row'>
                        <div className='col-md-2 mx-auto pb-2' align="center">
                            <img src={image_src} width={"100px"} height={"100px"} alt='dialxprt' className='image-border'/>
                        </div>
                        <div className='col-md-9'>
                            <div className="">
                                {ribbon}
                                <div className="title">
                                    <h1>{d.businessName}</h1>
                                    <h4 className="text-light">{d.category}</h4>
                                </div>
                                <div className="content">
                                    <div className="social employee-email">
                                        <p className='m-0' title={d.email}><i className="fa fa-envelope" aria-hidden="true"></i><span>{d.email}</span></p>
                                    </div>
                                    <div className="social" align="center">
                                        <p className='m-0' align="center"><button className="btn btn-primary" onClick={() => {dispatch(headersSaveEdit(d.id))}}>Click Here to Contact</button></p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        </div>
                        
                    </li>
        )
    }):<h2 className='text-danger'>Records Not Found</h2>;
    return (
        <div className="container mt-5 text-white">
            <div className="row">
                <div className="col-md-12 mt-5">
                    <form className="d-flex">
                        <input  onChange={(e) => filterEmployees(e.target.value)}  className="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
                    </form>
                </div>
                    <div>
                        <ul className="list-group mt-5">
                {data}
                        </ul>
                    </div>
            </div>
            {toggleEditValue?<Details/>:""}
        </div>




    );
}

export default Employee;
