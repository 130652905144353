const initialState = {
    toggleEdit: false,
    detailsID: 0,
}
function baseReducer(
    state = initialState ,
    action
) {
    switch (action.type) {
        case 'headers_save_edit':
            localStorage.setItem('toggleEdit', !state.toggleEdit);
            console.log(action.id)
            // localStorage.setItem('isDisabled', !state.isDisabled);
            return { ...state,toggleEdit: !state.toggleEdit ,detailsID:action.id }
        default:
            return state
    }
}
export default baseReducer;