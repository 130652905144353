import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getEmployeeDetails } from '../../state/reducers/apiReducer';
import { headersSaveEdit } from '../../state/actions/baseActions'
import { mediaBaseUrl } from "../variable/static_variables";
function Details() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getEmployeeDetails())
    }, [dispatch]);

    const employee_data = useSelector(state => state.postReducer.employeedetails);
    const mobile = "tel:" + employee_data.mobile
    return (
        <div classNameName="container mt-5 text-white">
            <div className="modal fade show" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style={{ "display": "block" }}>
                <div className="modal-body col-md-4 mx-auto">
                    <div className="employee-card details-card" align="center">
                        {(employee_data.businessPhoto) ? <img src={mediaBaseUrl + employee_data.businessPhoto} alt="dailxprt" width={"100%"} /> : ""}
                        <div class="row mt-2 text-dark social-buttons-all">
                            {
                                (employee_data.website_url)?
                                <div class="col">
                                    <a href={employee_data.website_url} target="_blank" rel="noreferrer">
                                        <i className="fa fa-chrome" aria-hidden="true" title="website"></i><br/>Website
                                    </a>                                    
                                </div>:""
                            }
                            {
                                (employee_data.whatsapp)?
                                <div class="col">
                                    <a href={"https://api.whatsapp.com/send?phone=91"+employee_data.whatsapp+"&text=Hi%2C%0AI%20found%20your%20contact%20on%20Dialxprt"}>
                                        <i className="fa fa-whatsapp" aria-hidden="true" title="Whatsapp"></i><br/>Whatsapp
                                    </a>
                                </div>:""
                            }
                            {
                                (employee_data.twitter_url)?
                                <div class="col">
                                    <a href={employee_data.twitter_url} target="_blank" rel="noreferrer">
                                        <i className="fa fa-twitter" aria-hidden="true" title="Twitter"></i><br/>Twitter
                                    </a>
                                </div>:""
                            }
                            {
                                (employee_data.instagram_url)?
                                <div class="col">
                                    <a href={employee_data.instagram_url} target="_blank" rel="noreferrer">
                                        <i className="fa fa-instagram" aria-hidden="true" title="Instagram"></i><br/>Instagram
                                    </a>
                                </div>:""
                            }
                            {
                                (employee_data.facebook_url)?
                                <div class="col">
                                    <a href={employee_data.facebook_url} target="_blank" rel="noreferrer">
                                        <i className="fa fa-facebook" aria-hidden="true" title="Facebook"></i><br/>Facebook
                                    </a>
                                </div> :""
                            }
                            
                            {
                                (employee_data.youtube_url)?
                                <div class="col">
                                    <a href={employee_data.youtube_url} target="_blank" rel="noreferrer">
                                        <i className="fa fa-youtube" aria-hidden="true" title="YouTube"></i><br/>YouTube
                                    </a>
                                </div>:""
                            }
                            
                            
                            
                             
                                                
                        </div>
                        <button type="button" className="btn-close fixed-right text-white" onClick={() => { dispatch(headersSaveEdit(0)) }}></button>
                        <div className="card-body">
                            {/* {JSON.stringify(employee_data)} */}
                            <h5 className="employee-title">Name: {employee_data.businessName}</h5>
                            <p className="employee-category"> Work: {employee_data.category}</p>
                            <p className="employee-category">{(employee_data.addresses)?"Address: "+employee_data.addresses:""}</p>
                            <p className="card-text"><b>Mobile: {employee_data.mobile}</b></p>
                            <a href={mobile} className="btn btn-primary">Call</a>
                            <div className="btn btn-secondary mr-2" onClick={() => { dispatch(headersSaveEdit(0)) }}>Close</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Details;
