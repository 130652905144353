import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Categories from "./categories";
import Employee from "./employee";
import Registration from '../Expert/registration';
function RoutePage(){   
    return (
            <div className="container-fluid">
                <div className="row justify-content-start">
                    <div className="col-lg-12">
                    <BrowserRouter>
                        <Routes>
                            <Route path="/categories/:id" element={<Categories />} />
                            {/* <Route path="/categories" element={<Categories />} /> */}
                            <Route path="/Service-Provider/:id" element={<Employee testFor={"BLS"} />}/>
                            <Route path="/Expert-Registration" element={<Registration testFor={"BLS"} />}/>
                            <Route path="*" element={<NoPage />} />
                            <Route path="/" element={<Categories />}/>
                        </Routes>
                    </BrowserRouter>
                    </div>
                </div>
            </div>
    )
}
const NoPage = () => {
    return <h1 className='m-5'>404</h1>;
  };
export default RoutePage;