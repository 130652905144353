import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getCategories, getAddresses, createRegistration } from '../../state/reducers/apiReducer';
import * as React from 'react';
// import { Link } from 'react-router-dom';
// import { staticBaseUrl } from "../variable/static_variables";
function Registration() {

    const [activeTab, setActiveTab] = useState('tab1');
    const [progressPercentage, setProgressPercentage] = useState('10%');
    // const [category_id, setCategory] = useState('');
    const emailRef = React.useRef();
    const passwordRef = React.useRef();
    // const address_idsRef = React.useRef();
    // const category_idRef = React.useRef();
    const business_nameRef = React.useRef();
    const business_descriptionRef = React.useRef();
    const experienceRef = React.useRef();
    const business_photoRef = React.useRef();
    const person_nameRef = React.useRef();
    const person_photoRef = React.useRef();
    const mobileRef = React.useRef();
    const whatsappRef = React.useRef();
    const address_strRef = React.useRef();
    const google_map_linkRef = React.useRef();
    const website_urlRef = React.useRef();
    // const facebook_urlRef = React.useRef();
    // const instagram_urlRef = React.useRef();
    // const youtube_urlRef = React.useRef();
    // const twitter_urlRef = React.useRef();
    const refererNameRef = React.useRef();
    const refererMobileRef = React.useRef();
    













    const handleTabChange = (tab) => {
        if (tab === 'tab1') {
            setProgressPercentage('10%')
        } else if (tab === 'tab2') {
            if(business_nameRef.current.value ===""){
                alert('Business Name is Required')
            }else if(mobileRef.current.value  ===""){
                alert('Mobile is Required')
            }else if(whatsappRef.current.value ===""){
                alert('Whatsapp is Required')
            }else if(address_strRef.current.value ===""){
                alert('Address is Required')
            }else if(person_nameRef.current.value ===""){
                alert('Name is Required')
            }else if(emailRef.current.value  ===""){
                alert('Email is Required')
            }else{
                // console.log(emailRef.current)
                const formData = new FormData()
                formData.append('email',emailRef.current.value)
                // formData.append('password',passwordRef.current.value)
                // formData.append('address_ids',JSON.stringify([address_idsRef.current.value]))
                // formData.append('category_id',JSON.stringify([category_idRef.current.value]))
                formData.append('business_name',business_nameRef.current.value)
                formData.append('business_description',business_descriptionRef.current.value)
                formData.append('experience',experienceRef.current.value)
                // formData.append('business_photo',business_photoRef.current.files[0])
                formData.append('person_name',person_nameRef.current.value)
                // formData.append('person_photo',person_photoRef.current.files[0])
                formData.append('mobile',mobileRef.current.value)
                formData.append('whatsapp',whatsappRef.current.value)
                formData.append('address_str',address_strRef.current.value)
                formData.append('google_map_link',google_map_linkRef.current.value)
                formData.append('website_url',website_urlRef.current.value)
                // formData.append('facebook_url',facebook_urlRef.current.value)
                // formData.append('instagram_url',instagram_urlRef.current.value)
                // formData.append('youtube_url',youtube_urlRef.current.value)
                // formData.append('twitter_url',twitter_urlRef.current.value)
                formData.append('refererName',refererNameRef.current.value)
                formData.append('refererMobile',refererMobileRef.current.value)
                setProgressPercentage('100%')
                dispatch(createRegistration(formData))
                setActiveTab(tab);
            }
        } else if (tab === 'tab3') { 
            if(!business_photoRef.current.files[0]){
                alert('Business Photo Or Card Photo is Required')
            }else if(!person_photoRef.current.files[0]){
                alert('Profile Photo is Required')
            }else{
                // console.log(emailRef.current)
                const formData = new FormData()
                formData.append('email',emailRef.current.value)
                formData.append('password',passwordRef.current.value)
                // formData.append('address_ids',JSON.stringify([address_idsRef.current.value]))
                // formData.append('category_id',JSON.stringify([category_idRef.current.value]))
                formData.append('business_name',business_nameRef.current.value)
                formData.append('business_description',business_descriptionRef.current.value)
                formData.append('experience',experienceRef.current.value)
                formData.append('business_photo',business_photoRef.current.files[0])
                formData.append('person_name',person_nameRef.current.value)
                formData.append('person_photo',person_photoRef.current.files[0])
                formData.append('mobile',mobileRef.current.value)
                formData.append('whatsapp',whatsappRef.current.value)
                formData.append('address_str',address_strRef.current.value)
                formData.append('google_map_link',google_map_linkRef.current.value)
                formData.append('website_url',website_urlRef.current.value)
                // formData.append('facebook_url',facebook_urlRef.current.value)
                // formData.append('instagram_url',instagram_urlRef.current.value)
                // formData.append('youtube_url',youtube_urlRef.current.value)
                // formData.append('twitter_url',twitter_urlRef.current.value)
                formData.append('refererName',refererNameRef.current.value)
                formData.append('refererMobile',refererMobileRef.current.value)
                setProgressPercentage('100%')
                dispatch(createRegistration(formData))
                setActiveTab(tab);
            }
        }
    };
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAddresses())
        dispatch(getCategories(100001))
    }, [dispatch]);
    // const allcategories = useSelector(state => state.postReducer.allcategories);
    // const address = useSelector(state => state.postReducer.addresses);
    const create_status = useSelector(state => state.postReducer.create_status);
    return (
        <div className="container-fluid mt-5" id='multi-form'>
            <div className="row justify-content-center mt-2">
                <div className="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
                    <div className="card px-0 pt-4 pb-0 mt-3 mb-3">
                        <h2 id="heading">Boost Your Business Register On DialXprt.</h2>
                        <p>Fill required form field to go to next step</p>
                        <form id="msform">
                            <ul id="progressbar">
                                <li id="account" className='active'><strong>Info</strong></li>
                                {/* <li id="personal" className={activeTab !== 'tab1' ? 'active' : ''}><strong>Personal</strong></li> */}
                                {/* <li id="payment" className={activeTab !== 'tab1' && activeTab !== 'tab2' ? 'active' : ''}><strong>Image</strong></li> */}
                                <li id="confirm" className={activeTab === 'tab2' ? 'active' : ''}><strong>Finish</strong></li>
                            </ul>
                            <div className="progress">
                                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100" style={{ width: progressPercentage }}></div>
                            </div> <br />
                            <fieldset style={{display:(activeTab === 'tab1'? 'block':'none')}}>
                                    <div className="form-card">
                                        <div className="row">
                                            <div className="col-7">
                                                <h2 className="fs-title">Account Information:</h2>
                                            </div>
                                            <div className="col-5">
                                                <h2 className="steps">Step 1 - 2</h2>
                                            </div>
                                        </div>
                                        <label className="fieldlabels">Business Name: <span className='text-danger'>*</span></label>
                                        <input type="text" ref={business_nameRef} placeholder="Business Name" />
                                        <label className="fieldlabels">Business Description: </label>
                                        <input type="text" ref={business_descriptionRef} placeholder="Business Description" />
                                        <label className="fieldlabels">Mobile: <span className='text-danger'>*</span></label>
                                        <input type="number" name="mobile" ref={mobileRef} placeholder="Mobile" />
                                        <label className="fieldlabels">whatsapp: <span className='text-danger'>*</span></label>
                                        <input type="number" name="whatsapp" ref={whatsappRef} placeholder="whatsapp" />
                                        <label className="fieldlabels">Address: <span className='text-danger'>*</span></label>
                                        <input type="text" name="address" ref={address_strRef} placeholder="Address" />
                                        <label className="fieldlabels">Google Map Link: </label>
                                        <input type="text" name="google_map_link" ref={google_map_linkRef} placeholder="Google Map Link" />
                                        <label className="fieldlabels">website Url: </label>
                                        <input type="text" name="website_url" ref={website_urlRef} placeholder="website Url" />
                                        {/* <label className="fieldlabels">Facebook Url: </label>
                                        <input type="number" name="facebook_url" ref={facebook_urlRef} placeholder="Facebook Url" />
                                        <label className="fieldlabels">Instagram Url: </label>
                                        <input type="number" name="instagram_url" ref={instagram_urlRef} placeholder="Instagram Url" />
                                        <label className="fieldlabels">Youtube Url: </label>
                                        <input type="number" name="youtube_url" ref={youtube_urlRef} placeholder="Youtube Url" />
                                        <label className="fieldlabels">Twitter Url: </label>
                                        <input type="number" name="twitter_url" ref={twitter_urlRef} placeholder="Twitter Url" /> */}
                                        <label className="fieldlabels">Name: <span className='text-danger'>*</span></label>
                                        <input type="text" ref={person_nameRef} placeholder="Name" />
                                        <label className="fieldlabels">Email: <span className='text-danger'>*</span></label>
                                        <input type="text" ref={emailRef} placeholder="Email" />
                                        {/* <label className="fieldlabels">Password (future use): <span className='text-danger'>*</span></label>
                                        <input type="text" ref={passwordRef} placeholder="Password" /> */}
                                        {/* <label className="fieldlabels">Work Address: <span className='text-danger'>*</span></label>
                                        <select name="address_ids" ref={address_idsRef}>
                                            <option value="">Select Work Address</option>
                                            {address.map(a =>{
                                                return (<option value={a.id} key={a.id}>{a.name}</option>)
                                            })}
                                        </select> */}
                                        {/* <label className="fieldlabels">Work Category: <span className='text-danger'>*</span></label>
                                        <select name="categoryId" ref={category_idRef}>
                                            <option value="">Select Work Category</option>
                                            {allcategories?.map(a =>{
                                                return (<option value={a.id} key={a.id}>{a.name}</option>)
                                            })}
                                        </select> */}
                                        <label className="fieldlabels">Experience: </label>
                                        <input type="text" ref={experienceRef} placeholder="Experience" />
                                        <label className="fieldlabels">Reference Name: </label>
                                        <input type="text" ref={refererNameRef} placeholder="Reference Name" />
                                        <label className="fieldlabels">Reference Mobile: </label>
                                        <input type="number" ref={refererMobileRef} placeholder="Reference Mobile" />
                                    </div>
                                    <input type="button" name="next" className="next action-button" value="Submit" onClick={() => handleTabChange('tab2')} />
                                </fieldset>
                                {/* <fieldset style={{display:(activeTab === 'tab2'? 'block':'none')}}>
                                    <div className="form-card">
                                        <div className="row">
                                            <div className="col-7">
                                                <h2 className="fs-title">Image Upload:</h2>
                                            </div>
                                            <div className="col-5">
                                                <h2 className="steps">Step 2 - 3</h2>
                                            </div>
                                        </div>
                                        <label className="fieldlabels">Upload Your Photo <span className='text-danger'>*</span>:</label>
                                        <input type="file" ref={person_photoRef} accept="image/*" />
                                        <label className="fieldlabels">Upload Your Business Photo (Card or Shop) <span className='text-danger'>*</span>:</label>
                                        <input type="file" ref={business_photoRef} accept="image/*" />
                                    </div>
                                    <input type="button" name="next" className="next action-button" value="Submit" onClick={() => handleTabChange('tab3')} />
                                    <input type="button" name="previous" className="previous action-button-previous" value="Previous" onClick={() => handleTabChange('tab1')} />
                                </fieldset> */}
                                <fieldset style={{display:(activeTab === 'tab2'? 'block':'none')}}>
                                {create_status?
                                <div className="form-card">
                                        <div className="row">
                                            <div className="col-7">
                                                <h2 className="fs-title">Finish:</h2>
                                            </div>
                                            <div className="col-5">
                                                <h2 className="steps">Step 2 - 2</h2>
                                            </div>
                                        </div> <br /><br />
                                        <h2 className="purple-text text-center"><strong>SUCCESS !</strong></h2> <br />
                                        <div className="row justify-content-center">
                                            <div className="col-3"> <img src="https://i.imgur.com/GwStPmg.png" className="fit-image" alt="success"/> </div>
                                        </div> <br /><br />
                                        <div className="row justify-content-center">
                                            <div className="col-7 text-center">
                                              <h5 className="purple-text text-center">You Have Successfully Registered</h5>
                                            </div>
                                        </div>
                                    </div>
                                    :<div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}
                                </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Registration;
